<template>
  <PromiseContainer v-model="computedPromises">
    <template v-slot:default>
      <v-row>
        <v-col cols="12" md="auto" class="flex-grow-1 flex-shrink-1">
          <PageLinkHeader :router-link="detailViewRouteLocation" :link-text="'Terug naar ' + (itemName || '')" :header="title" />
        </v-col>
        <slot name="after-header"/>
      </v-row>
      <v-row>
        <v-col>
          <PromiseContainer v-model="computedFormPromises">
            <v-form v-model="valid">
              <slot name="form-fields" />
              <v-row class="sticky-bottom bg-white">
                  <slot name="buttons-left" v-bind:valid="valid">
                    <v-col cols="12" :sm="hideSaveButton ? 12 : 'auto'" :class="{'flex-grow-1': !hideSaveButton}"></v-col>
                  </slot>
                <v-col v-if="!hideSaveButton" cols="auto" class="d-flex justify-end flex-grow-0">
                  <v-btn class="secondary" @click="save" :disabled="disableSaveButton"><slot name="save-button-content">Opslaan</slot></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </PromiseContainer>
        </v-col>
      </v-row>

    </template>
  </PromiseContainer>
</template>

<script>
import PageLinkHeader from "./PageLinkHeader.vue";
import PromiseContainer from "./PromiseContainer.vue";

export default {
  name: "DetailItemEditCard.vue",
  components: {
    PromiseContainer,
    PageLinkHeader
  },
  props: {
    confirmationMessage: {
      type: String,
      required: false
    },
    itemName: {
      type: String,
    },
    title: {
      type: String,
      required: true
    },
    detailViewRouteLocation: {
      type: Object,
      required: true
    },
    successRouteLocation: {
      type: Object,
      default: null
    },
    successCallback: {
      type: Function,
      default: null
    },
    catchCallback: {
      type: Function,
      default: null
    },
    updateStoreAction: {
      type: String,
      required: false
    },
    updateStoreActionPayload: {
      type: Object,
      required: false
    },
    promises: {
      default: () => []
    },
    formPromises: {
      default: () => []
    },
    hideSaveButton: {
      type: Boolean,
      default: false
    },
    overrideDisableButton: {
      type: Boolean,
      default: null
    },
    customResponseDataToConfirmMessageHandler: {
      type: Function,
      default: null,
      required: false
    }
  },
  emits: ["update:promises"],
  data() {
    return {
      valid: false
    }
  },
  methods: {
    save() {
      if (this.updateStoreAction && this.updateStoreActionPayload) {
        this.promises.push(this.$store.dispatch(this.updateStoreAction, this.updateStoreActionPayload)
          .then((data) => {
            if (this.customResponseDataToConfirmMessageHandler) {
              this.promises.push(this.$store.dispatch("setSuccessMessage", { message: this.customResponseDataToConfirmMessageHandler(data) }))
            } else {
              this.promises.push(this.$store.dispatch("setSuccessMessage", { message: this.confirmationMessage }))
            }

            if (this.successCallback) {
              this.successCallback(data)
            } else {
              this.$router.push(this.successRouteLocation || this.detailViewRouteLocation)
            }
          }).catch((data) => {
            if (this.catchCallback) {
              this.catchCallback(data)
            }
          })
        )
      }
      this.$emit('save')
    }
  },
  computed: {
    computedPromises: {
        get() {
            return this.promises
        },
        set(newValue) {
            this.$emit("update:promises", newValue)
        }
    },
    computedFormPromises: {
        get() {
            return this.formPromises
        },
        set(newValue) {
            this.$emit("update:formPromises", newValue)
        }
    },
    disableSaveButton(){
      if(this.overrideDisableButton != null){
        return this.overrideDisableButton
      } else {
        return !this.valid
      }

    }
  }
}
</script>
